//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-384:_176,_8224,_8310,_4414,_6156,_5540,_8572,_4564;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-384')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-384', "_176,_8224,_8310,_4414,_6156,_5540,_8572,_4564");
        }
      }catch (ex) {
        console.error(ex);
      }